import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from './link'
import slug from 'slug';

const PageBox = ({ data, index, snodo, page }) => {
  let eurl = snodo ?`/${slug(snodo)}/`:null;
  eurl = page ? eurl.concat(`${slug(page)}/`): eurl;
  const { icona, titolo, sommario } = data;

  return (
    <div className={`lanci text-center ${index===1?'lanci--bottom':'mt-4'} `}>
      <GatsbyImage  image={getImage(icona)} alt={titolo} className="lanci__icon" />
      <div>
        {!!eurl ? (
          <Link to={eurl} title={titolo} className="lanci__title">{titolo}</Link>
        ):(
          <div className="lanci__title">{titolo}</div>
        )}
      </div>
      <div className="lanci__text" dangerouslySetInnerHTML={{ __html: sommario.childMarkdownRemark.html }}></div>
    </div>
  );
}

export default PageBox;